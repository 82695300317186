import * as React from "react";
import { Helmet } from "react-helmet";
import { Layout } from "../components/legacy";
import cn from "classnames";
import LOGO from "../../img/legacy/logo-white.svg";
import MS_LOGIN from "../../img/legacy/ms-symbollockup_signin_light.svg";

import style from "./login.module.css";
import { Config } from "../constants";
import { Color } from "../constants/legacy";

export default class LoginPage extends React.Component {
  state = {
    email: "",
    password: "",
    loginError: false,
    oauthError: "",
    requireSSO: false,
  };

  componentDidMount() {
    const params = new URLSearchParams(window.location.search);
    if (params.has("error")) {
      switch (params.get("error")) {
        case "NO_LINKED_USER":
          this.setState({
            oauthError: `We couldn't find a Shipamax account with the email address ${params.get(
              "email"
            )}. Please check that you are using the correct Microsoft account.`,
          });
          break;
        case "AUTHENTICATION_ERROR":
        default:
          this.setState({
            oauthError: `Could not log in with Microsoft: Error during authentication.`,
          });
      }
    }
  }

  handleInputChange = (e: React.FormEvent<HTMLInputElement>) => {
    this.setState({
      [e.currentTarget.name]: e.currentTarget.value.trim(),
    });
  };

  handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    const { password } = this.state;
    const email = this.state.email.toLowerCase();
    this.setState({ loginError: false });

    if (email && password) {
      const response = await fetch(`${Config.apiServer}/api/users/login`, {
        method: "POST",
        mode: "cors",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ email, password }),
      });

      const json = await response.json();

      if (response.ok && json && json.id) {
        document.location.href = `${Config.appServer}/?token=${json.id}`;
      } else {
        if (response.status === 405)
          this.setState({ requireSSO: true, loginError: false });
        else this.setState({ loginError: true, requireSSO: false });
      }
    }
  };

  render() {
    const { email, password } = this.state;
    return (
      <>
        <Layout footerBackColor={Color.darkBlue}>
          <Helmet>
            <title>Shipamax | Log In</title>
            <meta name="description" content="Access your Shipamax account." />
          </Helmet>

          {/* Banner */}
          <div className={style.root}>
            <div className={style.login__banner}>
              <div className="container">
                <div className={`row ${style.customRow}`}>
                  <div className="col s12">
                    <h2>Bulk &amp; Tankers</h2>
                    <div className={style.login__bannerContent}>
                      <h1 className={style.login__heading}>
                        Please Login
                        <img
                          className={cn(style.logo, style.logoHiddenMobile)}
                          src={LOGO}
                          alt="Logo"
                        />
                      </h1>
                      <form
                        className={style.loginForm}
                        onSubmit={this.handleSubmit}
                      >
                        <input
                          type="text"
                          name="email"
                          value={email}
                          onChange={this.handleInputChange}
                          placeholder="Email"
                          className={cn(style.formInput)}
                        />
                        <input
                          type="password"
                          name="password"
                          value={password}
                          onChange={this.handleInputChange}
                          placeholder="Password"
                          className={cn(style.formInput)}
                        />
                        <div
                          className={cn(style.form__loginError, {
                            [style.active]: this.state.loginError,
                          })}
                        >
                          Incorrect username or password. Please double-check
                          and try again.
                        </div>
                        <div
                          className={cn(style.form__loginError, {
                            [style.active]: this.state.requireSSO,
                          })}
                        >
                          Your organisation requires sign in using Microsoft
                          Sign In.
                        </div>
                        <div className={style.form__buttons}>
                          <button type="submit" className={style.formSubmit}>
                            Login
                          </button>
                          <div className={style.resetPw}>
                            <a className={style.link} href="/forgot">
                              Forgotten Password?
                            </a>
                          </div>
                        </div>
                        <div className={style.msLogin}>
                          <a href={`${Config.apiServer}/azure/login`}>
                            <img src={MS_LOGIN} alt="Sign in with Microsoft" />
                          </a>
                          <div
                            className={cn(style.form__loginError, {
                              [style.active]: this.state.oauthError,
                            })}
                          >
                            {this.state.oauthError}
                          </div>
                        </div>
                      </form>
                    </div>

                    <div className={style.otherOptions}>
                      <div className={style.login__bannerContent}>
                        <div className={style.otherOptions__area}>
                          Not In Bulk?
                          <a
                            className={cn(style.link, style.freightLink)}
                            href={`${Config.emServer}/`}
                          >
                            Switch to Freight Forwarding
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Layout>
      </>
    );
  }
}
